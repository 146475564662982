<template>
    <div class="warp">
        <div>
            <section class="doorcard">
                <div class="img-bg"></div>
                <div class="content white">
                    <h3>手机就是门卡<br/>碰一碰就开门</h3>
                    <span class="desc">用户通过钱包App模拟实体门卡/开通智能门锁卡后，即可拿起手机，一碰开门。刷卡无需解锁/无需联网，覆盖社区、公司、智能门锁等多场景，一部手机代替所有门卡。</span>
                    <div class="video" id="playVideo" @click="videoModal = true">
                        <img src="https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/buscard/play.svg" alt="play">
                        <span>点击视频展示</span>
                    </div>
                </div>
                <ul class="door-intro">
                    <li>
                        <div>
                            <p>模拟实体门卡</p>
                            <span>模拟小区、公司、校园门卡到手机使用</span>
                        </div>
                        <img src="https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/doorcard/simulation.png" alt="">
                    </li>
                    <li>
                        <div>
                            <p>小区门卡</p>
                            <span>在线开通小区门卡到手机</span>
                        </div>
                        <img src="https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/doorcard/doorcard.png" alt="">
                    </li>
                    <li>
                        <div>
                            <p>自定义空白卡</p>
                            <span>线上发空白卡线下写入数据使用</span>
                        </div>
                        <img src="https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/doorcard/custom-card.png" alt="">
                    </li>
                    <li>
                        <div>
                            <p>智能门锁</p>
                            <span>添加智能门锁卡到手机使用</span>
                        </div>
                        <img src="https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/doorcard/custom-card.png" alt="">
                    </li>
                </ul>
            </section>
            <section style="padding-top: 4vw;">
                <div class="content-part">
                    <img class="img-part" src="https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/doorcard/new-way.jpg" alt="">
                    <div class="text">
                        <p class="title-part">解锁新方式，贴一下就开门</p>
                        <div>
                            <span class="circle"></span>
                            <span class="intro">芯片级安全</span>
                        </div>
                        <div>
                            <span class="circle"></span>
                            <span class="intro">双向认证</span>
                        </div>
                        <div>
                            <span class="circle"></span>
                            <span class="intro">快速便捷</span>
                        </div>
                    </div>
                </div>
            </section>
            <section class="school-card">
                <div class="img-bg"></div>
                <div class="content white">
                <p class="title">
                    手机就是校园卡  畅行智能校园
                </p>
                <span class="desc">用户通过校方应用/小程序开通NFC校园卡功能，即可使用手机代替校园卡，畅行校园。</span>
                </div>
            </section>
            <section>
                <div class="support-device grey">
                    <p class="title">支持设备</p>
                    <Loading v-if="!deviceList.length" height="20"/>
                    <div class="phone-list">
                        <ul>
                            <li v-for="(device, index) in deviceList" :key="index">
                                <img :src="device.deviceUrl" alt="">
                                <p>{{device.deviceName}}</p>
                            </li>
                        </ul>
                        <p class="more">
                            <span @click="checkMore('Devices', '[门卡·车钥匙页面]查看更多机型及刷卡位置')">查看更多机型及刷卡位置 <img src="https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/buscard/check-more.svg" alt=""></span>
                        </p>
                    </div>
                </div>
            </section>
            <section>
                <ul class="explain">
                    <li v-for="(item, index) in doorCardExplainList" :key="index">{{item}}</li>
                </ul>
            </section>
            <Modal
                width="50"
                v-model="videoModal"
                title="手机就是门卡 碰一碰就开门"
                footer-hide>
                <video controls id="video-ele">
                    <source src="https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/doorcard_v2/doorcardVideo.mp4" type="video/mp4">
                    <p>Your browser doesn't support HTML5 video. Here is
                        a <a href="https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/doorcard_v2/doorcardVideo.mp4">link to the video</a> instead.</p>
                </video>
            </Modal>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import { quickCustomPropToTsmWebsite } from '@/common/sensorsSdk'
    import Loading from "@/components/Loading.vue";
    export default {
        name: 'DoorCard',
        data () {
            return {
                videoModal: false,
                deviceList: [],
                doorCardExplainList: []
            }
        },
        components: {
            Loading
        },
        async mounted () {
            quickCustomPropToTsmWebsite({websiteName: '门卡·车钥匙'})
            const deviceList = await axios.get(`https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/data/devices.json?num=${Math.random()}`)
            const doorCardExplainList = await axios.get(`https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/data/doorCardExplainList.json?num=${Math.random()}`)
            this.deviceList = deviceList.data.slice(0, 8)
            this.doorCardExplainList = doorCardExplainList.data
        },
        methods: {
            checkMore(routeName, text) {
                quickCustomPropToTsmWebsite({websiteClick: text})
                this.$router.push({name: routeName})
            }
        }
    }
</script>

<style scoped>
.warp section .img-bg{
    width: 100%;
    height: 41vw;
}
.warp section .content{
    z-index: 9;
    position: absolute;
    top: 16vw;
    left: 15vw;
    width: 30vw;
}
.warp section .content h3{
    font-size: 3.2vw;
    font-weight: 500;
}
.warp section .content p.title{
    font-size: 2vw;
}
.warp section .content span.desc{
    width: 80%;
    display: block;
    font-size: .9vw;
    margin-top: .5vw;
}
.doorcard{
    overflow-x: inherit;
}
.warp section.doorcard .img-bg{
    background: url("https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/doorcard_v2/open-door.png") no-repeat;
    background-size: cover;
}
.doorcard ul.door-intro{
    position: absolute;
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 101%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.doorcard .door-intro li{
    position: relative;
}
.doorcard .door-intro li p{
    position: absolute;
    top: 30%;
    left: 9%;
    font-size: 1.1vw;
    font-weight: 500;
}
.doorcard .door-intro li span{
    position: absolute;
    top: 49%;
    left: 9%;
    font-size: .9vw;
    width: 9vw;
}
.doorcard .door-intro li img{
    width: 20vw;
}
.warp section.school-card .img-bg {
  background: url("https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/home_v2/school-card.jpeg") no-repeat;
  background-size: cover;
}
.content-part{
    display: flex;
    justify-content: space-around;
    padding: 6vw 10vw;
    align-items: center;
}
.content-part .text .title-part{
    font-size: 1.9vw;
    font-weight: 500;
    margin-bottom: .7vw;
}
.text .circle{
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: #000;
    border-radius: 50%;
    border: 3.5px solid #ddd;
    margin-right: .5vw;
}
.text .intro{
    font-size: .9vw;
    line-height: 2vw;
}
.content-part .img-part{
    width: 24vw;
}
/* 支持设备 */
.support-device{
    display: flex;
    flex-direction: column;
    align-items: center;
    letter-spacing: .2px;
}
.support-device .title{
    font-size: 1.9vw;
    font-weight: 500;
    margin-top: 5vw;
}
.support-device .phone-list{
    width: 60%;
    padding-bottom: 7vw;
}
.support-device .phone-list ul{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.support-device .phone-list ul li{
    text-align: center;
    margin-top: 2.5vw;
    width: 25%;
}
.support-device .phone-list ul li img{
    height: 10vw;
}
.support-device .phone-list ul li p{
    font-size: 1.2vw;
    line-height: 4vw;
}
.support-device .phone-list .more{
    font-size: .8vw;
    letter-spacing: .4px;
    position: absolute;
    bottom: 3vw;
    left: 51%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}
.support-device .phone-list .more img{
    width: 1.1vw;
    position: relative;
    top: .2vw;
}
/* video */
.video{
    position: relative;
    display: inline-block;
    background: rgba(255,255,255,0.10);
    border: 1px solid rgba(255,255,255,0.20);
    border-radius: 1.1vw;
    padding: .35vw 1.0vw;
    font-size: .9vw;
    letter-spacing: .3px;
    margin-top: 2vw;
    opacity: .8;
    cursor: pointer;
}
.video img{
    position: absolute;
    width: 1vw;
    top: .45vw;
}
.video span{
    padding-left: 1.3vw;
}
#video-ele{
    width: 100%;
    border: 0;
    overflow: hidden;
}
</style>